import { Injectable, inject } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { GraphQLFormattedError } from 'graphql';
import { TranslateService } from '@ngx-translate/core';
import { ApolloErrorSeverity } from '@desquare/enums';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private translateService = inject(TranslateService);
  private toastrService = inject(ToastrService);

  private async tryTranslate(message: string, messageParams?: Object) {
    const translatedMessage = await lastValueFrom(
      this.translateService.get(message, messageParams),
    ).catch(() => message);

    return translatedMessage;
  }

  async success(
    message: string = 'Success',
    title?: string,
    messageParams?: Object,
    options?: Partial<IndividualConfig>,
  ) {
    const translatedMessage = await this.tryTranslate(message, messageParams);
    if (title) {
      const translateTitle = await this.tryTranslate(title);
      return this.toastrService.success(
        translatedMessage,
        translateTitle,
        options,
      );
    }
    return this.toastrService.success(translatedMessage, title, options);
  }

  async info(
    message: string,
    title?: string,
    messageParams?: Object,
    options?: Partial<IndividualConfig>,
  ) {
    const translatedMessage = await this.tryTranslate(message, messageParams);
    if (title) {
      const translateTitle = await this.tryTranslate(title);
      return this.toastrService.info(
        translatedMessage,
        translateTitle,
        options,
      );
    }
    return this.toastrService.info(translatedMessage, title, options);
  }

  async error(
    message: string = 'An unknown error has occurred',
    title?: string,
    messageParams?: Object,
    options?: Partial<IndividualConfig>,
  ) {
    const translatedMessage = await this.tryTranslate(message, messageParams);
    if (title) {
      const translateTitle = await this.tryTranslate(title);
      return this.toastrService.error(
        translatedMessage,
        translateTitle,
        options,
      );
    }
    return this.toastrService.error(translatedMessage, title, options);
  }

  async warning(
    message: string,
    title?: string,
    messageParams?: Object,
    options?: Partial<IndividualConfig>,
  ) {
    const translatedMessage = await this.tryTranslate(message, messageParams);
    if (title) {
      const translateTitle = await this.tryTranslate(title);
      return this.toastrService.error(
        translatedMessage,
        translateTitle,
        options,
      );
    }
    return this.toastrService.warning(translatedMessage, title, options);
  }

  async clear(toastrId?: number) {
    return this.toastrService.clear(toastrId);
  }

  handleGqlError(error: GraphQLFormattedError<Record<string, any>>) {
    if (error.extensions) {
      const { severity, code } = error.extensions.exception;

      const message = code || error.message;

      switch (severity) {
        case ApolloErrorSeverity.DEBUG:
        case ApolloErrorSeverity.INFO:
        case ApolloErrorSeverity.SILLY:
          this.info(message);
          break;
        case ApolloErrorSeverity.WARNING:
          this.warning(message);
          break;
        case ApolloErrorSeverity.ERROR:
        default:
          this.error(message);
      }
    }
  }
}
