import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import {
  CreateProfileGQL,
  DeactivateProfileGQL,
  DeleteProfileGQL,
  DestroyAllCloudyMediaGQL,
  GetMeProfilesDocument,
  GetProfileDetailedGQL,
  Profile,
  PurgeProfileGQL,
  UpdateProfileGQL,
  UpdateProfileInput,
} from '@designage/gql';
import { IProfileForm } from '@desquare/interfaces';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { ToasterService } from '../toaster/toaster.service';
import { ApolloError } from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  createProfileGQL = inject(CreateProfileGQL);
  updateProfileGQL = inject(UpdateProfileGQL);
  deactivateProfileGQL = inject(DeactivateProfileGQL);

  toasterService = inject(ToasterService);

  constructor(
    public getProfile: GetProfileDetailedGQL,
    private deleteProfile: DeleteProfileGQL,
    public destroy: DestroyAllCloudyMediaGQL,
    public purge: PurgeProfileGQL,
  ) {}
  /** tell opened list components to refresh */
  @Output() profileListChanged = new EventEmitter(); // <boolean>();

  async createProfile(form: IProfileForm) {
    console.log('createProfile', form);

    const query = await lastValueFrom(
      this.createProfileGQL.mutate({
        input: {
          name: form.name,
          locationInput: form.locationInput,
          ownerId: form.ownerId,
          userIds: form.userIds,
          description: form.description,
          organizationUid: form.organizationUid,
        },
      }),
    )
      .then(({ data }) => {
        if (
          data &&
          data.createProfile.isSuccessful &&
          data.createProfile.profile
        ) {
          this.toasterService.success('CREATE_PROFILE_SUCCESS');
          this.profileListChanged.emit();
          return data.createProfile.profile;
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
          throw new Error('Unknown error');
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('createProfile', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
        return undefined;
      });
    return query;
  }

  async updateProfile(
    profileInput: UpdateProfileInput,
  ): Promise<Profile | undefined> {
    // console.log('updateProfile', profileInput);
    const query = lastValueFrom(
      this.updateProfileGQL.mutate({ input: profileInput }),
    )
      .then(({ data }) => {
        console.log('updateProfile data', data);
        if (data?.updateProfile?.isSuccessful && data?.updateProfile?.profile) {
          this.toasterService.success('UPDATE_PROFILE_SUCCESS');
          return data.updateProfile.profile as Profile;
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
          throw new Error('Unknown error');
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('updateProfile', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
        return undefined;
      });
    return query;
  }

  async deactivateProfile(
    profileId: string,
    deleteOn: Date,
  ): Promise<Profile | undefined> {
    const query = lastValueFrom(
      this.deactivateProfileGQL.mutate({
        id: profileId,
        deleteOn,
      }),
    )
      .then(({ data }) => {
        if (
          data?.deactivateProfile.isSuccessful &&
          data?.deactivateProfile.profile
        ) {
          this.toasterService.success('DEACTIVATE_PROFILE_SUCCESS');
          return data.deactivateProfile.profile as Profile;
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
          return undefined;
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('updateProfile', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
        return undefined;
      });
    return query;
  }
}
